<template>
  <pdfviewer :pdfurl="pdfurl" />
</template>
<script>
import urlencode from "urlencode";
import {getReportfUrl} from '@/api/report'; 
import pdfviewer from '@/components/PdfView';

export default {
  name: 'Report',
  components: {
     pdfviewer
  }
  ,data(){
    return{
        pdfurl:"err.pdf"
    }
  },mounted(){
      getReportfUrl().then((response) => {
        this.pdfurl=urlencode(response.data);
      });
  }
}
</script>

<style scoped>

</style>
